import React from "react"
import styled from "styled-components"

import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const Content = styled.div`
  margin-bottom: 55px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 100px;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
`
const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
`
const AllNormalContent = content => {
  return (
    <div>
      <Container>
        <StyledRow>
          <Col xs={10}>
            <Content dangerouslySetInnerHTML={{ __html: content?.content }} />
          </Col>
        </StyledRow>
      </Container>
    </div>
  )
}

export default AllNormalContent
